import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import SignaturePage from "./pages/SignaturePage";
import SignatureCapturePage from "./pages/SignatureCapturePage";
import ErrorPage from "./pages/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <SignaturePage /> },
      { path: "signature", element: <SignaturePage /> },
      { path: "sig", element: <SignaturePage /> },
      { path: "capture", element: <SignatureCapturePage /> },
      { path: "cap", element: <SignatureCapturePage /> },
      { path: "error", element: <ErrorPage /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
