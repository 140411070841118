import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import SignaturePad from "../components/signaturePad";
import * as FirestoreService from "../utils/firebase";

/**
 * SignaturePage
 * @description Page for EMPLOYEE view
 */

function SignaturePage() {
  const contextData = useOutletContext();

  if (!contextData.layout) {
    // Update October 23: Employee view will now open as a small modal within POS so hide everything except sig component
    contextData.layout = "light";
  }

  const [isLoading, setIsLoading] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState(null);

  const { showBoundary } = useErrorBoundary();
  const onError = (error) => showBoundary(error);

  useEffect(() => {
    const checkSignatureStatus = async () => {
      /* Check the signature status:
          1) If signature already exists in database (Firestore) then display it
          2) Else Begin a stream on signature collection (customerSignatures) and wait for customer to sign 
      */
      const document = await FirestoreService.getSignature(
        contextData.orderId,
        onError
      );

      if (!document) return;

      if (document.exists()) {
        setSignatureUrl(document.data().signatureUrl);
      } else {
        setIsLoading(true);

        /* Subscribe to the customerSignatures stream */
        const unsubscribe = FirestoreService.streamSignature(
          contextData.orderId,
          (querySnapshot) => {
            if (querySnapshot.exists()) {
              setSignatureUrl(querySnapshot.data().signatureUrl);
              setIsLoading(false);
            }
          },
          onError
        );

        return unsubscribe;
      }
    };

    /* Check if an error was propagated */
    if (!contextData.error) checkSignatureStatus();
  }, [contextData.orderId, contextData.error]);

  return (
    <>
      <SignaturePad
        {...contextData}
        isLoading={isLoading}
        signatureUrl={signatureUrl}
        error={contextData.error}
        onError={onError}
      />
    </>
  );
}

export default SignaturePage;
