const CLOUDINARY_CLOUD_NAME = "djekaop1p";
const CLOUDINARY_UPLOAD_PRESET = "kjtukpla";
const CLOUDINARY_SIGNATURE_FOLDER_NAME = "signatures";
const CLOUDINARY_GET_URL = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/[VERSION]/${CLOUDINARY_SIGNATURE_FOLDER_NAME}/[PUBLICID]`;
const CLOUDINARY_POST_URL =
  "https://api.cloudinary.com/v1_1/" + CLOUDINARY_CLOUD_NAME + "/auto/upload";

// short random string for ids - not guaranteed to be unique
const randomId = function (length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

export const generatePublicId = (prefix = "") => `${prefix}${randomId(11)}`;

// deprecated
export const publicIdStatus = (publicId) => {
  return new Promise((resolve, reject) => {
    const version = (+new Date()).toString().substring(0, 10);

    fetch(
      CLOUDINARY_GET_URL.replace("[VERSION]", `v${version}`).replace(
        "[PUBLICID]",
        publicId
      )
    )
      .then((response) => {
        if (response.status === 404) resolve(null);
        else if (response.status === 200) resolve(response.url);
        else resolve(null);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadImage = (image, publicId, onError) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
    formData.append("file", image);
    formData.append("public_id", publicId);
    formData.append("tags", "signature");

    fetch(CLOUDINARY_POST_URL, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        onError(error);
      });
  });
};
