import { useOutletContext } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import SignaturePad from "../components/signaturePad";

/**
 * SignatureCapturePage
 * @description Page for CUSTOMER view
 */

function SignatureCapturePage() {
  const contextData = useOutletContext();

  if (!contextData.layout) {
    contextData.layout = "normal";
  }

  const { showBoundary } = useErrorBoundary();

  return (
    <>
      <SignaturePad
        {...contextData}
        error={contextData.error}
        onError={(error) => showBoundary(error)}
      />
    </>
  );
}

export default SignatureCapturePage;
