export const StructubeLogo = ({ styles }) => {
  return (
    <svg
      className={styles.headerLogo}
      width="648"
      height="83.2"
      viewBox="0 0 648 83.2"
    >
      <title id="logoIcon-aria-title-react-aria2755518836-7">Structube</title>
      <desc id="logoIcon-aria-desc-react-aria2755518836-7">
        Modern furniture and accessories at the lowest possible prices
      </desc>
      <g>
        <g>
          <path
            fill="#CF0A2C"
            d="M59.84,55.67a23.59,23.59,0,0,1-8.77,19Q42.31,82,27.26,82A59.19,59.19,0,0,1,12.1,80.24,71.74,71.74,0,0,1,0,75.89V57H2a39.92,39.92,0,0,0,12.45,7.73,36.31,36.31,0,0,0,13.27,2.7A38,38,0,0,0,32,67.09,15.07,15.07,0,0,0,36.33,66a10.56,10.56,0,0,0,3.39-2.4,6.69,6.69,0,0,0-.62-8.76A13,13,0,0,0,33.43,52c-2.65-.7-5.42-1.36-8.35-2A57.24,57.24,0,0,1,16.9,47.6Q8.08,44.36,4.22,38.81C1.63,35.11.35,30.51.35,25A22,22,0,0,1,9.12,7C15,2.33,22.48,0,31.67,0A62.43,62.43,0,0,1,45.36,1.51,57.81,57.81,0,0,1,57,5.27v18.1H55.12a35.87,35.87,0,0,0-10.33-6.54A31.25,31.25,0,0,0,32.31,14.2a26.9,26.9,0,0,0-4.45.37,14.57,14.57,0,0,0-4.3,1.36,8.21,8.21,0,0,0-3.12,2.53,6.12,6.12,0,0,0-1.31,3.85,5.76,5.76,0,0,0,2.11,4.81c1.41,1.11,4.05,2.14,7.93,3.06,2.58.61,5,1.18,7.4,1.75a73.44,73.44,0,0,1,7.6,2.33q8,3,11.85,8.11T59.84,55.67Z"
          ></path>
          <path
            fill="#CF0A2C"
            d="M133.39,15.89H111.11v66H92.61v-66H70.33V0h63.06Z"
          ></path>
          <path
            fill="#CF0A2C"
            d="M216.08,81.93H193.19L173.68,51.81h-8.09V81.93h-18.5V0h31A62.23,62.23,0,0,1,189,.82a22.07,22.07,0,0,1,8.58,3.58,20.09,20.09,0,0,1,6.4,7.11,22.68,22.68,0,0,1,2.38,11q0,9.08-3.68,14.78a28.75,28.75,0,0,1-10.52,9.51ZM187.15,24.88a12,12,0,0,0-1.1-5.33,7.67,7.67,0,0,0-3.71-3.49A12.25,12.25,0,0,0,178,15c-1.64-.13-3.56-.19-5.77-.19h-6.64V37h5.66a45.07,45.07,0,0,0,7.29-.5,9.71,9.71,0,0,0,5-2.28,10.44,10.44,0,0,0,2.8-3.8A15,15,0,0,0,187.15,24.88Z"
          ></path>
          <path
            fill="#CF0A2C"
            d="M282.91,51.19q0,14.89-8,22.8c-5.31,5.29-13.15,7.92-23.46,7.92S233.32,79.28,228,74s-8-12.85-8-22.74V0H238.3V50.13c0,5.58,1,9.73,3.09,12.48s5.43,4.09,10.08,4.09,7.92-1.31,10-3.94,3.17-6.83,3.17-12.63V0h18.24Z"
          ></path>
          <path
            fill="#CF0A2C"
            d="M332.44,82A39.42,39.42,0,0,1,318,79.42a30.78,30.78,0,0,1-11.42-7.75,35.09,35.09,0,0,1-7.41-12.83,54.59,54.59,0,0,1-2.64-17.76,54.36,54.36,0,0,1,2.51-17A36.87,36.87,0,0,1,306.27,11a30.63,30.63,0,0,1,11.36-8.11A37.88,37.88,0,0,1,332.49,0a54.09,54.09,0,0,1,8,.55A52.39,52.39,0,0,1,347.12,2a44.9,44.9,0,0,1,5.74,2.26c1.72.83,3.21,1.6,4.5,2.3V25.72h-2.08c-.87-.84-2-1.85-3.33-3a46.48,46.48,0,0,0-4.57-3.43,31.19,31.19,0,0,0-5.62-2.85,18,18,0,0,0-6.43-1.15,19.84,19.84,0,0,0-7.3,1.35,17.33,17.33,0,0,0-6.37,4.46,22.19,22.19,0,0,0-4.53,8,37.21,37.21,0,0,0-1.71,12.05,35.56,35.56,0,0,0,1.87,12.37A22.21,22.21,0,0,0,322,61.34a17.27,17.27,0,0,0,6.44,4.15,21.09,21.09,0,0,0,7,1.24,20.24,20.24,0,0,0,6.58-1.1,23.24,23.24,0,0,0,5.94-3,37.08,37.08,0,0,0,4.32-3.23c1.32-1.15,2.41-2.13,3.27-3h1.88V75.26q-2.66,1.32-5.08,2.48a41.7,41.7,0,0,1-5,2,59.07,59.07,0,0,1-6.5,1.68A46,46,0,0,1,332.44,82Z"
          ></path>
          <path
            fill="#CF0A2C"
            d="M433.48,15.9H411.2v66H392.69v-66H370.42V0h63.06Z"
          ></path>
          <path
            fill="#CF0A2C"
            d="M509.52,51.19q0,14.89-7.94,22.8t-23.33,7.92c-10.3,0-18.06-2.63-23.35-7.92S447,61.14,447,51.25V0h18.15V50.13c0,5.58,1,9.73,3.07,12.48s5.4,4.09,10,4.09,7.87-1.31,10-3.94,3.17-6.83,3.17-12.63V0h18.13Z"
          ></path>
          <path
            fill="#CF0A2C"
            d="M584.31,56.85a25.84,25.84,0,0,1-2.09,10.62,21.4,21.4,0,0,1-5.74,7.74,25.41,25.41,0,0,1-9.31,5.18,45.15,45.15,0,0,1-12.87,1.54H523V0h27.88a86.69,86.69,0,0,1,12.67.66,24,24,0,0,1,8,2.92,15,15,0,0,1,6,6.36,20.11,20.11,0,0,1,2,9.11,19.93,19.93,0,0,1-2.72,10.49,18.49,18.49,0,0,1-7.75,7.08v.44a19.21,19.21,0,0,1,11.06,6.6Q584.31,48.67,584.31,56.85ZM561,23a10,10,0,0,0-.91-4.13,5.88,5.88,0,0,0-3.15-3.07,13.46,13.46,0,0,0-5.06-1c-2-.06-4.86-.08-8.53-.08h-1.76V32.36h2.93c2.95,0,5.47,0,7.53-.17a11.92,11.92,0,0,0,4.89-1.12,6.33,6.33,0,0,0,3.3-3.32A13.56,13.56,0,0,0,561,23Zm4.13,33.62a11.84,11.84,0,0,0-1.31-6.07,7.84,7.84,0,0,0-4.44-3.18,20.1,20.1,0,0,0-5.93-.77c-2.49,0-5.11-.07-7.83-.07h-4V67.15h1.35c5.14,0,8.81,0,11-.06a14,14,0,0,0,6.13-1.44A7.83,7.83,0,0,0,564,62,12.33,12.33,0,0,0,565.08,56.66Z"
          ></path>
          <path
            fill="#CF0A2C"
            d="M649.73,81.89H598V0h51.74V15.89H616.49v14H647.4V45.8H616.49V66h33.24Z"
          ></path>
        </g>
      </g>
    </svg>
  );
};
