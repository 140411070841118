import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import * as Utils from "../utils";
import * as FirestoreService from "../utils/firebase";
import classes from "./RootLayout.module.css";

function RootLayout() {
  const [searchParams] = useSearchParams();
  const [boundaryError, setBoundaryError] = useState(null);

  const location = useLocation();

  const lowerCasedParams = new URLSearchParams(
    [...searchParams].map(([key, value]) => [key.toLowerCase(), value])
  );

  // UPDATE SEPT 20, 2023: Due to size limitation in SMS (160 characters), accept both "ord" & "orderid"
  const orderId =
      lowerCasedParams.get("ord") || lowerCasedParams.get("orderid"),
    line = lowerCasedParams.get("line"),
    name = lowerCasedParams.get("name"),
    title = lowerCasedParams.get("title") || "SIGNATURE",
    pathName = location.pathname?.toLowerCase(),
    lang = lowerCasedParams.get("lang") || "en",
    layout = lowerCasedParams.get("layout"),
    header = lowerCasedParams.get("header");

  if (!orderId) {
    throw new Error("Missing Manadatory Parameters!");
  }

  let showHeader = true;
  if (!header) {
    if (pathName === "/signature" || pathName === "/sig") {
      // Update October 23: Employee view will now open as a small modal within POS so hide everything except sig component
      showHeader = false;
    }
  } else {
    showHeader = header === "1" ? true : false;
  }

  // React component responsible for returning a fallback UI based on a thrown value [Official definition from 'react-error-boundary' doc]
  // In my case there is no UI because it resets error automatically and passes error message down to the child components for display
  function FallbackComponent({ error, resetErrorBoundary }) {
    useEffect(() => resetErrorBoundary(error), []);
    return <></>;
  }

  // Log error in database (Firestore)
  async function logErrorToService(error, info) {
    FirestoreService.setError(
      { orderId, name, title, pathName, lang },
      `${error}${info.componentStack}`
    );
  }

  function getFriendlyErrorMessage(errorMessage) {
    // Cleanup error message
    if (errorMessage.startsWith("Function setDoc() called with invalid data"))
      errorMessage = "Function setDoc() called with invalid data";

    switch (errorMessage) {
      case "Upload preset not found":
        return lang === "en"
          ? "Unable to upload image"
          : "Impossible de télécharger l'image";
      case "Function setDoc() called with invalid data":
        return lang === "en"
          ? "Unable to save signature into the database"
          : "Impossible d'enregistrer la signature dans la base de données";
      default:
        return errorMessage;
    }
  }

  return (
    <>
      {showHeader ? (
        <header className={classes.headerContainer}>
          <Utils.StructubeLogo styles={classes} />
        </header>
      ) : null}
      <main>
        <ErrorBoundary
          FallbackComponent={FallbackComponent}
          onError={logErrorToService}
          onReset={(propagatedError) => {
            let errorMessage =
              lang === "en" ? "Unknown Error" : "Erreur inconnue"; // Never set this to NULL or empty string. Will cause eternal loop!
            if (propagatedError.args) {
              if (
                typeof propagatedError.args[0] === "object" &&
                propagatedError.args[0].message
              ) {
                errorMessage = propagatedError.args[0].message;
              } else if (
                typeof propagatedError.args[0] === "string" &&
                propagatedError.args[0]
              ) {
                errorMessage = propagatedError.args[0];
              }
            }

            setBoundaryError(getFriendlyErrorMessage(errorMessage));
          }}
          resetKeys={[boundaryError]}
        >
          <Outlet
            context={{
              orderId,
              line,
              name,
              title,
              pathName,
              lang,
              layout,
              querystring: location.search,
              error: boundaryError,
            }}
          />
        </ErrorBoundary>
      </main>
    </>
  );
}

export default RootLayout;
