import { useState, useEffect, useRef } from "react";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";
import * as CloudinaryService from "../utils/cloudinary";
import * as FirestoreService from "../utils/firebase";
import styles from "./signaturePad.module.css";

/**
 * SignaturePad
 * @description Component that uses the react-signature-canvas package to capture the users signature
 */

const SignaturePad = (props) => {
  const isFrench = props.lang?.toLowerCase() === "fr" ? true : false;

  let isCustomerView = false;
  switch (props.pathName) {
    case "/":
    case "/signature":
    case "/sig":
      isCustomerView = false;
      break;
    case "/capture":
    case "/cap":
      isCustomerView = true;
      break;
    default:
      break;
  }

  const [openModel, setOpenModal] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [imageFinalized, setImageFinalized] = useState(false);

  const lightFormat = props.layout === "light";
  const signatureTitle = lightFormat
    ? `Signature - ${props.orderId}`
    : "Signature";

  const sigCanvas = useRef();

  const publicId = `SIG_${props.orderId}${
    props.line && props.line !== "0" ? "_" + props.line : ""
  }`;

  useEffect(() => {
    const checkSignatureStatus = async () => {
      if (!isCustomerView) {
        // EMPLOYEE VIEW
        if (props.signatureUrl) setImageURL(props.signatureUrl);
      } else {
        // CUSTOMER VIEW
        const document = await FirestoreService.getSignature(
          props.orderId,
          props.onError
        );

        if (!document) return;

        if (document.exists()) {
          setImageURL(document.data().signatureUrl);
          setImageFinalized(true);
        }
      }
    };

    if (!props.error) checkSignatureStatus();
  }, [isCustomerView, props.orderId, props.signatureUrl, props.error]);

  const uploadToCloudinary = async () => {
    const response = await CloudinaryService.uploadImage(
      imageURL,
      publicId,
      props.onError
    );

    return response;
  };

  const addToFirestore = async (signatureUrl) => {
    await FirestoreService.setSignature(
      props.orderId,
      signatureUrl,
      props.querystring,
      props.onError
    );
  };

  const dataUploadHandler = async () => {
    const response = await uploadToCloudinary();

    if (!response) return;

    const responseObj = JSON.parse(response);

    if (responseObj.error && responseObj.error.message) {
      props.onError(responseObj.error.message);
      return;
    }

    await addToFirestore(responseObj.secure_url);

    setImageFinalized(true);
  };

  const saveImageUrl = () => {
    const base64ImageUrl = sigCanvas.current.getCanvas().toDataURL("image/png");
    if (sigCanvas.current.isEmpty()) return;
    setImageURL(base64ImageUrl);
    setOpenModal(false);
  };

  return (
    <>
      <div className={styles.app}>
        {lightFormat ? (
          <></>
        ) : (
          <>
            <h1>{props.title}</h1>
            <h2 className="h1">{props.orderId}</h2>
          </>
        )}
      </div>
      <div className={styles.app}>
        {lightFormat ? (
          <></>
        ) : (
          <div className={styles.contentBoxWrapper}>
            <div className={styles.contentBox}>
              <div className={`${styles.gridContent} ${styles.resetSpace}`}>
                <div className={styles.fieldWrapper}>
                  <h3 className={styles.fieldLabel}>Date:</h3>
                  <h4 className={styles.fieldValue}>
                    {isFrench
                      ? moment().format("DD-MM-YY HH:mm")
                      : moment().format("YYYY-MM-DD LT")}
                  </h4>
                </div>
                {props.name ? (
                  <div className={styles.fieldWrapper}>
                    <h3 className={styles.fieldLabel}>Client:</h3>
                    <h4 className={styles.fieldValue}>{props.name}</h4>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}

        <div className={styles.contentBoxWrapper}>
          <div className={styles.contentBox}>
            <h3 className={styles.title}>{signatureTitle}</h3>
            {props.error ? (
              <h5 className={styles.error}>{props.error}</h5>
            ) : (
              <>
                {!isCustomerView && props.isLoading && !imageURL ? (
                  <h4 className={styles.value}>
                    {isFrench
                      ? "En attente de la signature du client..."
                      : "Waiting for customer signature..."}
                  </h4>
                ) : null}
                {isCustomerView ? (
                  <div className={styles.actions}>
                    {imageFinalized ? (
                      <button className={styles.finalized}>
                        {isFrench ? "Merci!" : "Thank you!"}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (imageURL) dataUploadHandler();
                          else setOpenModal(true);
                        }}
                      >
                        {imageURL
                          ? isFrench
                            ? "Accepter la signature"
                            : "Accept Signature"
                          : isFrench
                          ? "Signer le reçu"
                          : "Click here to sign"}
                      </button>
                    )}
                  </div>
                ) : null}
                {imageURL ? (
                  <div className={styles.imageContainer}>
                    {isCustomerView && !imageFinalized ? (
                      <button
                        onClick={() => {
                          setOpenModal(true);
                        }}
                        className={styles.modify}
                      >
                        {isFrench ? "Modifier" : "Edit"}
                      </button>
                    ) : null}
                    <img
                      src={imageURL}
                      alt="signature"
                      className={styles.signature}
                    />
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.app}>
        {openModel && (
          <div className={styles.modalContainer}>
            <div className={styles.modal}>
              <div className={styles.sigPadContainer}>
                <SignatureCanvas
                  penColor="black"
                  throttle={20}
                  canvasProps={{ className: styles.sigCanvas }}
                  ref={sigCanvas}
                />
                <button onClick={() => sigCanvas.current.clear()}>
                  {isFrench ? "Effacer" : "Clear"}
                </button>
              </div>

              <div className={`${styles.modal__bottom} ${styles.actions}`}>
                <button className={styles.save} onClick={saveImageUrl}>
                  {isFrench ? "Sauvegarder" : "Save"}
                </button>
                <button
                  className={styles.cancel}
                  onClick={() => setOpenModal(false)}
                >
                  {isFrench ? "Annuler" : "Cancel"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SignaturePad;
