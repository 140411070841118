import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import {
  getFirestore,
  onSnapshot,
  collection,
  getDoc,
  getDocs,
  doc,
  addDoc,
  setDoc,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_RECAPTCHA_SITE_KEY
  ),
  isTokenAutoRefreshEnabled: true,
});

export const getSignature = async (orderId, onError) => {
  try {
    const signatureColRef = doc(db, "customerSignatures", orderId);
    return await getDoc(signatureColRef);
  } catch (error) {
    if (error.hasOwnProperty("message")) error.message += " [GETSIGNATURE]";
    onError(error);
  }
};

export const setSignature = async (
  orderId,
  signatureUrl,
  querystring,
  onError
) => {
  try {
    return await setDoc(doc(db, "customerSignatures", orderId), {
      created: serverTimestamp(),
      orderId,
      signatureUrl,
      querystring,
    });
  } catch (error) {
    if (error.hasOwnProperty("message")) error.message += " [SETSIGNATURE]";
    onError(error);
  }
};

export const streamSignature = (orderId, snapshot, onError) => {
  return onSnapshot(doc(db, "customerSignatures", orderId), snapshot, onError);
};

/*
  Get error logs by running the following code:
    const querySnapshot = await FirestoreService.getError(orderId);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
    });
*/
export const getError = async (orderId) => {
  try {
    return await getDocs(
      query(collection(db, "errorLogs"), where("orderId", "==", orderId))
    );
  } catch (error) {
    console.error(error);
  }
};

export const setError = async (contextData, errorMessage) => {
  try {
    return await addDoc(collection(db, "errorLogs"), {
      created: serverTimestamp(),
      ...contextData,
      errorMessage,
    });
  } catch (error) {
    console.error(error);
  }
};
